import { Badge } from '@mui/material';
import { Button, LIcon } from '@s_mart/core';
import { lineHorn } from '@s_mart/regular-icons';
import { NovidadesPopper } from './novidadesPopper/novidadesPopper';
import { useNovidades } from './useNovidades';

export const Novidades = () => {
  const { mostrarPopper, setMostrarPopper, handleAbrirNovidades, novidadesFiltradas, mostrarBadge } = useNovidades();

  return (
    <>
      {mostrarPopper !== null && (
        <NovidadesPopper
          anchorEl={mostrarPopper}
          onClose={() => setMostrarPopper(null)}
          novidadesItems={novidadesFiltradas}
        />
      )}
      <Button
        variant='text'
        color='neutral'
        startIcon={
          <Badge
            variant='dot'
            color='error'
            anchorOrigin={{
              horizontal: 'right',
              vertical: 'bottom',
            }}
            invisible={!mostrarBadge}>
            <LIcon icon={lineHorn} />
          </Badge>
        }
        onClick={handleAbrirNovidades}>
        Novidades
      </Button>
    </>
  );
};
