import { ZodApiResponseParser } from 'api/zodApi';
import { EnumTipoUsuarioAgronota } from 'constants/usuariosAgronota';
import { z } from 'zod';

export enum EnumTipoUsuarioListagem {
  CONVITE,
  USUARIO,
}

const schemaUsuario = z.object({
  codigoUsuario: z.number().nullable(),
  codigoConvite: z.number().nullable(),
  tipo: z.nativeEnum(EnumTipoUsuarioListagem),
  tipoUsuario: z.nativeEnum(EnumTipoUsuarioAgronota).nullable(),
  nome: z.string(),
  email: z.string(),
  dataUltimoLogin: z.string().nullable(),
  dataInativacao: z.string().nullable(),
});

const schemaListarUsuarios = z.object({
  data: z.array(schemaUsuario),
});

export type ListarUsuariosResponse = z.infer<typeof schemaListarUsuarios>;

export type ItemListarUsuariosResponse = z.infer<typeof schemaUsuario>;

export const parseListarUsuariosResponse = ZodApiResponseParser.createParser(schemaListarUsuarios);
