import { useCallback, useEffect, useMemo, useState } from 'react';
import useAuthStore from 'store/auth/auth';
import useAppStore from 'store/app/app';
import { api } from 'api/api';
import { useQueryClient } from '@tanstack/react-query';
import useAnalytics from 'hooks/useAnalytics';
import { ANALYTICS_ACTIONS } from 'constants/analyticsActions';

const useOnboardingAgronota = () => {
  const [loadingCadastrosIniciais, setLoadingCadastrosIniciais] = useState(false);
  const [recursosDisponiveis, buscarConfiguracoes] = useAuthStore((state) => [
    state.recursosDisponiveis,
    state.buscarConfiguracoes,
  ]);
  const { analyticsEventTracker } = useAnalytics();
  const [setMostrarOnboarding, setModalClienteOnboarding, handleErrors] = useAppStore((state) => [
    state.setMostrarOnboarding,
    state.setModalClienteOnboarding,
    state.handleErrors,
  ]);

  const queryClient = useQueryClient();

  const mensagemLoadingCadastrosIniciais = useMemo(
    () =>
      recursosDisponiveis?.['livro-caixa'] || recursosDisponiveis?.['escrituracao-automatica']
        ? 'Estamos cadastrando um plano de contas e inteligência fiscal para você!'
        : undefined,
    [recursosDisponiveis],
  );

  const handleFinalizarOnboarding = useCallback(async () => {
    try {
      setLoadingCadastrosIniciais(true);
      if (recursosDisponiveis?.['livro-caixa'] || recursosDisponiveis?.['escrituracao-automatica']) {
        await api.planoDeContas.excluirContasClientesVinculados();
      }
      await api.configuracoes.marcarOnboardingComoConcluido();
      setModalClienteOnboarding({
        primeiroCliente: true,
      });
      buscarConfiguracoes();
      queryClient.removeQueries({ queryKey: ['resumo-total-regras'] });
      setTimeout(() => setMostrarOnboarding(false), 300);
    } catch (error) {
      handleErrors(error);
    } finally {
      setLoadingCadastrosIniciais(false);
    }
  }, [recursosDisponiveis]);

  useEffect(() => {
    analyticsEventTracker({
      action: ANALYTICS_ACTIONS.ONBOARDING,
    });
  }, []);

  return {
    loadingCadastrosIniciais,
    mensagemLoadingCadastrosIniciais,
    handleFinalizarOnboarding,
  };
};

export default useOnboardingAgronota;
