import { SituacoesClientesAgronota } from 'containers/clientesAgronota/filtrosClientesAgronota/filtrosClientesAgronota.types';

export const ANALYTICS_ACTIONS = {
  RESUMO: {
    CERTIFICADOS_VENCIDOS: 'resumo_certificados_vencidos',
    CERTIFICADOS_VENCENDO: 'resumo_certificados_vencendo',
    PRODUTORES_SEM_CERTIFICADO: 'resumo_produtores_sem_certificado',
    NOTAS_EMITIDAS_REVISAR: 'resumo_notas_emitidas_revisar',
    NCMS_SEM_REGRAS: 'resumo_ncms_sem_regras',
    LANCAMENTOS_VENCIDOS: 'resumo_lancamentos_vencidos',
    DOCUMENTOS_NAO_PROCESSADOS: 'resumo_documentos_nao_processados',
  },
  CLIENTES: {
    NOVO_CLIENTE: 'clientes_novo_cliente',
    FILTROS: (situacao: SituacoesClientesAgronota) => {
      switch (situacao) {
        case SituacoesClientesAgronota.TODOS:
          return 'clientes_filtro_todos';
        case SituacoesClientesAgronota.ATIVOS:
          return 'clientes_filtro_ativos';
        case SituacoesClientesAgronota.INATIVOS:
          return 'clientes_filtro_inativos';
        case SituacoesClientesAgronota.BLOQUEADOS:
          return 'clientes_filtro_bloqueados';
        case SituacoesClientesAgronota.COM_PENDENCIAS:
          return 'clientes_filtro_todos_com_pendencias';
        case SituacoesClientesAgronota.CERTIFICADOS_VENCIDOS:
          return 'clientes_filtro_certificados_vencidos';
        case SituacoesClientesAgronota.CERTIFICADOS_VENCER:
          return 'clientes_filtro_certificados_vencendo';
        case SituacoesClientesAgronota.CLIENTES_SEM_CERTIFICADO:
          return 'clientes_filtro_sem_certificado';
        case SituacoesClientesAgronota.NOTAS_REVISAR:
          return 'clientes_filtro_notas_emitidas_revisar';
        case SituacoesClientesAgronota.LANCAMENTOS_VENCIDOS:
          return 'clientes_filtro_lancamentos_vencidos';
        case SituacoesClientesAgronota.DOCUMENTOS_NAO_PROCESSADOS:
          return 'clientes_filtro_documentos_nao_processados';
        case SituacoesClientesAgronota.SEM_PENDENCIAS:
          return 'clientes_filtro_sem_pendencias';
        default:
          return 'clientes_filtro';
      }
    },
  },
  PRODUTOS: {
    NOVO_PRODUTO: 'produtos_novo_produto',
    EDITAR_PRODUTO: 'produtos_editar_produto',
    EXCLUIR_PRODUTO: 'produtos_excluir_produto',
  },
  SIMULADOR_IR: {
    SELECIONAR_PRODUTOR: 'simulador_selecionar_produtor',
    SELECIONAR_ANO: (ano: string) => `simulador_selecionar_ano_${ano}`,
    NOVO_PRODUTOR: 'simulador_novo_produtor',
    EDITAR_PRODUTOR: 'simulador_editar_produtor',
    SIMULAR_IMPOSTO: 'simulador_simular_imposto',
    ENVIAR_POR_EMAIL: 'simulador_enviar_por_email',
    IMPRIMIR: 'simulador_imprimir',
    VOLTAR_A_TELA_INICIAL: 'simulador_voltar_tela_inicial',
  },
  CATEGORIAS: {
    NOVA_CATEGORIA: 'categorias_nova_categoria',
    NOVA_SUBCATEGORIA: 'categorias_nova_subcategoria',
    EDITAR: 'categorias_editar_categoria',
    EXCLUIR: 'categorias_excluir_categoria',
  },
  CONFIGURACOES: {
    USUARIOS: 'configuracoes_usuarios',
    CONVIDAR_USUARIO: 'configuracoes_convidar_usuario',
    MINHA_CONTA: 'configuracoes_minha_conta',
    MEU_PLANO: 'configuracoes_meu_plano',
    LANCAMENTOS: 'configuracoes_lancamentos',
  },
  ESCRITURACAO_AUTOMATICA: {
    ESCRITURACAO_RETROATIVA: 'automacoes_escrituracao_retroativa',
  },
  NATUREZAS_OPERACAO: {
    CRIAR: 'naturezas_nova_natureza',
    EDITAR: 'naturezas_editar_natureza',
    ATIVAR: 'naturezas_ativar_natureza',
    INATIVAR: 'naturezas_inativar_natureza',
  },
  NOVIDADES: {
    ABRIR: 'novidades_abrir',
    REDIRECT: 'novidades_redirect',
  },
  ONBOARDING: 'onboarding',
  USUARIOS_AGRONOTA: {
    NOVO_USUARIO: 'usuarios_agronota_novo',
    CANCELAR_FLUXO: 'usuarios_agronota_cancelar_fluxo',
    EDITAR_USUARIO: 'usuarios_agronota_editar',
    EXCLUIR_USUARIO: 'usuarios_agronota_excluir',
    ENVIAR_CONVITE: 'usuarios_agronota_enviar_convite',
  },
} as const;
