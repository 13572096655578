import { axiosInstance } from 'api/axiosConfig';
import { API_URL_AGRONOTA } from 'api/urls';
import { parseListarUsuariosResponse } from './schemas/listar';
import type { EditarUsuarioAgronotaBody } from './schemas/editar';
import type { ConvidarUsuarioAgronotaBody } from './schemas/enviarConvite';

export const usuariosAgronota = {
  listar: (codigoEmpresa: number) =>
    axiosInstance.get(`${API_URL_AGRONOTA}/parceiro/usuarios/${codigoEmpresa}`).then(parseListarUsuariosResponse),

  editar: (body: EditarUsuarioAgronotaBody) => axiosInstance.patch(`${API_URL_AGRONOTA}/parceiro/usuarios`, body),

  enviarConvite: (body: ConvidarUsuarioAgronotaBody) =>
    axiosInstance.post(`${API_URL_AGRONOTA}/parceiro/usuarios/convite`, body),

  excluirConvite: (codigoEmpresa: number, codigoConvite: number) =>
    axiosInstance.delete(`${API_URL_AGRONOTA}/parceiro/usuarios/convite/${codigoEmpresa}/${codigoConvite}`),

  excluirUsuario: (codigoEmpresa: number, codigoUsuario: number) =>
    axiosInstance.delete(`${API_URL_AGRONOTA}/parceiro/usuarios/${codigoEmpresa}/${codigoUsuario}`),
};
